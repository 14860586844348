import { Image } from "react-vant";
import styles from "./index.module.css";

interface EmptyProps {
  text?: string;
  className?: string;
}

export default ({ text = "暂无数据", className }: EmptyProps) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <Image
        className={styles.image}
        src={require("@/assets/images/empty.png").default}
      />
      <div className={styles.text}>{text}</div>
    </div>
  );
};
